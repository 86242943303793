"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontSize = exports.fontSizeNameScale = void 0;
const layout_1 = require("@carbon/layout");
const type_1 = require("@carbon/type");
exports.fontSizeNameScale = ['xs', 'sm', 'base', 'lg', 'xl'];
exports.fontSize = type_1.scale.reduce((acc, size, index) => {
    const key = index < exports.fontSizeNameScale.length ? exports.fontSizeNameScale[index] : `${index - exports.fontSizeNameScale.length + 2}xl`;
    acc[key] = (0, layout_1.rem)(size);
    return acc;
}, {});
