const INGEST_URL = 'https://api.axiom.co/v1/datasets/ui/ingest';
const DEFAULT_HEADERS = {
  authorization: `Bearer ${process.env.AXIOM_TOKEN}`,
  'content-type': 'application/x-ndjson',
};

export const ingest = <T extends object>(data: T) => fetch(INGEST_URL, {
  method: 'POST',
  headers: DEFAULT_HEADERS,
  body: JSON.stringify({
    source: 'standalone',
    ...data,
  }),
}).catch((err) => {
  console.error(err);
});
