import React, { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export function FigmaLogo(props: Props) {
  return (
    <svg width="33" height="50" viewBox="0 0 33 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.25 49.9999C12.804 49.9999 16.5 46.2666 16.5 41.6666V33.3333H8.25C3.696 33.3333 0 37.0666 0 41.6666C0 46.2666 3.696 49.9999 8.25 49.9999Z" fill="#0ACF83" />
      <path d="M0 25.0001C0 20.4001 3.696 16.6667 8.25 16.6667H16.5V33.3334H8.25C3.696 33.3334 0 29.6001 0 25.0001Z" fill="#A259FF" />
      <path d="M0 8.33333C0 3.73333 3.696 0 8.25 0H16.5V16.6667H8.25C3.696 16.6667 0 12.9333 0 8.33333Z" fill="#F24E1E" />
      <path d="M16.5 0H24.75C29.304 0 33 3.73333 33 8.33333C33 12.9333 29.304 16.6667 24.75 16.6667H16.5V0Z" fill="#FF7262" />
      <path d="M33 25.0001C33 29.6001 29.304 33.3334 24.75 33.3334C20.196 33.3334 16.5 29.6001 16.5 25.0001C16.5 20.4001 20.196 16.6667 24.75 16.6667C29.304 16.6667 33 20.4001 33 25.0001Z" fill="#1ABCFE" />
    </svg>
  );
}
