import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement>;

export function IndependentCornersIcon(props: Props) {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1 30C1 13.9837 13.9837 1 30 1H37C39.2091 1 41 2.79086 41 5C41 7.20914 39.2091 9 37 9H30C18.402 9 9 18.402 9 30V37C9 39.2091 7.20914 41 5 41C2.79086 41 1 39.2091 1 37V30ZM59 5C59 2.79086 60.7909 1 63 1H70C86.0163 1 99 13.9837 99 30V37C99 39.2091 97.2091 41 95 41C92.7909 41 91 39.2091 91 37V30C91 18.402 81.598 9 70 9H63C60.7909 9 59 7.20914 59 5ZM5 59C7.20914 59 9 60.7909 9 63V70C9 81.598 18.402 91 30 91H37C39.2091 91 41 92.7909 41 95C41 97.2091 39.2091 99 37 99H30C13.9837 99 1 86.0163 1 70V63C1 60.7909 2.79086 59 5 59ZM95 59C97.2091 59 99 60.7909 99 63V70C99 86.0163 86.0163 99 70 99H63C60.7909 99 59 97.2091 59 95C59 92.7909 60.7909 91 63 91H70C81.598 91 91 81.598 91 70V63C91 60.7909 92.7909 59 95 59Z" />
    </svg>
  );
}
