import Bugsnag from '@bugsnag/js';
import { isTRPCAuthError } from '../isTRPCAuthError';
import { ingest } from '../ingest';

export function handleUIError(
  err: unknown,
  handler?: (errorName: string | null, error: Error | null, raw: unknown) => void,
) {
  console.error(err);
  Bugsnag.notify(err instanceof Error ? err : String(err));
  const errorName = isTRPCAuthError(err)
    ? err.shape?.message ?? err.name ?? err.message
    : (err instanceof Error ? (err.name ?? err.message) : null);
  ingest({
    event: 'error',
    source: 'standalone',
    error: err,
  });
  handler?.(errorName, err instanceof Error ? err : null, err);
}
