import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const MoreIcon: React.FC<Props> = (props) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.52463 10.0073C4.35304 10.0114 5.02792 9.34314 5.032 8.51472C5.03608 7.6863 4.36783 7.01143 3.53941 7.00734C2.711 7.00326 2.03612 7.67151 2.03204 8.49993C2.02795 9.32835 2.69621 10.0032 3.52463 10.0073Z" />
    <path d="M8.52457 10.0319C9.35298 10.036 10.0279 9.36776 10.0319 8.53934C10.036 7.71093 9.36777 7.03605 8.53935 7.03197C7.71093 7.02788 7.03606 7.69614 7.03198 8.52456C7.02789 9.35297 7.69615 10.0279 8.52457 10.0319Z" />
    <path d="M13.5245 10.0566C14.3529 10.0607 15.0278 9.39242 15.0319 8.56401C15.036 7.73559 14.3677 7.06072 13.5393 7.05663C12.7109 7.05255 12.036 7.7208 12.0319 8.54922C12.0278 9.37764 12.6961 10.0525 13.5245 10.0566Z" />
  </svg>
);
