import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const DragHandleIcon: React.FC<Props> = (props) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.54927 5.00736C4.37769 5.01144 5.05256 4.34319 5.05665 3.51477C5.06073 2.68635 4.39247 2.01148 3.56406 2.00739C2.73564 2.00331 2.06077 2.67156 2.05668 3.49998C2.0526 4.3284 2.72085 5.00327 3.54927 5.00736Z" />
    <path d="M8.54921 5.032C9.37763 5.03608 10.0525 4.36783 10.0566 3.53941C10.0607 2.711 9.39241 2.03612 8.564 2.03204C7.73558 2.02795 7.06071 2.69621 7.05662 3.52463C7.05254 4.35304 7.72079 5.02792 8.54921 5.032Z" />
    <path d="M13.5491 5.05665C14.3776 5.06073 15.0524 4.39248 15.0565 3.56406C15.0606 2.73564 14.3924 2.06077 13.5639 2.05668C12.7355 2.0526 12.0606 2.72085 12.0566 3.54927C12.0525 4.37769 12.7207 5.05256 13.5491 5.05665Z" />
    <path d="M3.52463 10.0073C4.35304 10.0114 5.02792 9.34313 5.032 8.51471C5.03608 7.68629 4.36783 7.01142 3.53941 7.00733C2.711 7.00325 2.03612 7.6715 2.03204 8.49992C2.02795 9.32834 2.69621 10.0032 3.52463 10.0073Z" />
    <path d="M8.52457 10.0319C9.35298 10.036 10.0279 9.36777 10.0319 8.53935C10.036 7.71094 9.36777 7.03606 8.53935 7.03198C7.71093 7.02789 7.03606 7.69615 7.03198 8.52457C7.02789 9.35298 7.69615 10.0279 8.52457 10.0319Z" />
    <path d="M13.5245 10.0566C14.3529 10.0607 15.0278 9.39241 15.0319 8.564C15.036 7.73558 14.3677 7.06071 13.5393 7.05662C12.7109 7.05254 12.036 7.72079 12.0319 8.54921C12.0278 9.37763 12.6961 10.0525 13.5245 10.0566Z" />
    <path d="M5.00736 13.5146C5.00327 14.3431 4.3284 15.0113 3.49998 15.0072C2.67156 15.0032 2.00331 14.3283 2.00739 13.4999C2.01148 12.6714 2.68635 12.0032 3.51477 12.0073C4.34319 12.0114 5.01144 12.6862 5.00736 13.5146Z" />
    <path d="M10.0073 13.5393C10.0032 14.3677 9.32834 15.036 8.49992 15.0319C7.6715 15.0278 7.00325 14.3529 7.00733 13.5245C7.01142 12.6961 7.68629 12.0278 8.51471 12.0319C9.34312 12.036 10.0114 12.7109 10.0073 13.5393Z" />
    <path d="M15.0072 13.5639C15.0032 14.3924 14.3283 15.0606 13.4999 15.0565C12.6714 15.0524 12.0032 14.3776 12.0073 13.5491C12.0114 12.7207 12.6862 12.0525 13.5146 12.0566C14.3431 12.0606 15.0113 12.7355 15.0072 13.5639Z" />
  </svg>
);
