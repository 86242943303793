import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement>;

export const VariablesIcon: React.FC<Props> = (props) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.91211 6.51587C5.46436 6.51587 5.91211 6.0686 5.91211 5.51587C5.91211 4.96313 5.46436 4.51587 4.91211 4.51587C4.35986 4.51587 3.91211 4.96313 3.91211 5.51587C3.91211 6.0686 4.35986 6.51587 4.91211 6.51587Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3584 2.40259C9.66748 2.5813 9.8584 2.91138 9.8584 3.2688V7.80591C9.8584 8.16333 9.66748 8.49341 9.3584 8.67212L5.4292 10.9397C5.11963 11.1184 4.73828 11.1184 4.4292 10.9397L0.5 8.67212C0.333008 8.57544 0.200684 8.43481 0.114258 8.26978C0.0405273 8.12915 0 7.96997 0 7.80591V3.2688C0 2.91138 0.19043 2.5813 0.5 2.40259L4.4292 0.134033C4.73828 -0.0446777 5.11963 -0.0446777 5.4292 0.134033L9.3584 2.40259ZM4.4292 1.28931C4.73828 1.1106 5.11963 1.1106 5.4292 1.28931L8.3584 2.97974C8.49512 3.05884 8.60889 3.16821 8.69336 3.29614C8.79932 3.4563 8.8584 3.64673 8.8584 3.84595V7.22876C8.8584 7.58521 8.66748 7.91528 8.3584 8.09399L5.4292 9.7854C5.11963 9.96411 4.73828 9.96411 4.4292 9.7854L1.5 8.09399C1.19043 7.91528 1 7.58521 1 7.22876V3.84595C1 3.48853 1.19043 3.15845 1.5 2.97974L4.4292 1.28931Z"
    />
  </svg>
);
