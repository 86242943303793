import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement> & {
  type?: 'simple' | 'grouped'
};

export const ViewTypeIcon: React.FC<Props> = ({ type = 'grouped', ...props }) => {
  if (type === 'simple') {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="3" y="3.5" width="14" height="3" rx="1" />
        <rect x="3" y="8.5" width="14" height="3" rx="1" />
        <rect x="3" y="13.5" width="14" height="3" rx="1" />
      </svg>
    );
  }

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1" y="3.5" width="14" height="3" rx="1" />
      <rect x="3" y="8.5" width="14" height="3" rx="1" />
      <rect x="5" y="13.5" width="14" height="3" rx="1" />
    </svg>
  );
};
