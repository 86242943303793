"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boxShadow = exports.colors = exports.fontWeight = exports.fontSize = exports.screens = exports.spacing = exports.width = exports.height = void 0;
var height_1 = require("./height");
Object.defineProperty(exports, "height", { enumerable: true, get: function () { return height_1.height; } });
var width_1 = require("./width");
Object.defineProperty(exports, "width", { enumerable: true, get: function () { return width_1.width; } });
var spacing_1 = require("./spacing");
Object.defineProperty(exports, "spacing", { enumerable: true, get: function () { return spacing_1.spacing; } });
var screens_1 = require("./screens");
Object.defineProperty(exports, "screens", { enumerable: true, get: function () { return screens_1.screens; } });
var fontSize_1 = require("./fontSize");
Object.defineProperty(exports, "fontSize", { enumerable: true, get: function () { return fontSize_1.fontSize; } });
var fontWeight_1 = require("./fontWeight");
Object.defineProperty(exports, "fontWeight", { enumerable: true, get: function () { return fontWeight_1.fontWeight; } });
var colors_1 = require("./colors");
Object.defineProperty(exports, "colors", { enumerable: true, get: function () { return colors_1.colors; } });
var boxShadow_1 = require("./boxShadow");
Object.defineProperty(exports, "boxShadow", { enumerable: true, get: function () { return boxShadow_1.boxShadow; } });
