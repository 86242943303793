import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const HistoryIcon: React.FC<Props> = (props) => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M77.72 118.116C90.586 84.2095 123.375 60.1094 161.792 60.1094C211.438 60.1094 251.683 100.355 251.683 150C251.683 199.646 211.438 239.891 161.792 239.891C136.719 239.891 114.066 229.645 97.7448 213.074C91.9316 207.172 82.4344 207.1 76.5322 212.913C70.63 218.726 70.5578 228.223 76.3709 234.125C98.0988 256.186 128.359 269.891 161.792 269.891C228.006 269.891 281.683 216.214 281.683 150C281.683 83.7863 228.006 30.1094 161.792 30.1094C107.721 30.1094 62.0103 65.9043 47.0632 115.091L41.0533 106.518C37.0903 100.865 29.2952 99.4952 23.6424 103.458C17.9896 107.421 16.6197 115.216 20.5826 120.869L45.2435 156.046L45.2921 156.116C47.2582 158.92 50.1675 160.67 53.2904 161.238C53.5117 161.279 53.7341 161.313 53.9573 161.341C56.9332 161.719 60.0536 161.033 62.7034 159.175L62.7727 159.126L97.9497 134.465C103.602 130.503 104.972 122.707 101.009 117.055C97.0465 111.402 89.2514 110.032 83.5986 113.995L77.72 118.116Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.792 86.0615C170.077 86.0615 176.792 92.7773 176.792 101.062V143.815L202.828 169.851C208.686 175.709 208.686 185.206 202.828 191.064C196.971 196.922 187.473 196.922 181.615 191.064L151.186 160.635C148.373 157.822 146.792 154.007 146.792 150.028V101.062C146.792 92.7773 153.508 86.0615 161.792 86.0615Z"
    />
  </svg>
);
