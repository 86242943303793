"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boxShadow = void 0;
exports.boxShadow = {
    'inset-border': 'inset 0 0 0 1px var(--tw-shadow-color)',
    border: '0 0 0 1px var(--tw-shadow-color)',
    focus: 'inset 0 0 0 2px var(--tw-shadow-color)',
    outline: '0 0 0 2px var(--tw-shadow-color)',
    md: '0 5px 16px var(--tw-shadow-color)',
    lg: '0 5px 32px var(--tw-shadow-color)',
};
