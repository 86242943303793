import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement>;

export function IndependentSidesIcon(props: Props) {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M26 5C26 2.79086 27.7909 1 30 1H70C72.2091 1 74 2.79086 74 5C74 7.20914 72.2091 9 70 9H30C27.7909 9 26 7.20914 26 5ZM5 26C7.20914 26 9 27.7909 9 30V70C9 72.2091 7.20914 74 5 74C2.79086 74 1 72.2091 1 70V30C1 27.7909 2.79086 26 5 26ZM95 26C97.2091 26 99 27.7909 99 30V70C99 72.2091 97.2091 74 95 74C92.7909 74 91 72.2091 91 70V30C91 27.7909 92.7909 26 95 26ZM26 95C26 92.7909 27.7909 91 30 91H70C72.2091 91 74 92.7909 74 95C74 97.2091 72.2091 99 70 99H30C27.7909 99 26 97.2091 26 95Z" />
    </svg>
  );
}
