import React from 'react';
import { twMerge } from '@/utils/ui';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: 'primary' | 'secondary' | 'destructive' | 'cta'
};

export const Button: React.FC<Props> = ({
  variant, children, className = '', ...props
}) => (
  <button
    type="button"
    className={twMerge(
      'py-2 px-3 text-sm rounded-sm',
      'disabled:opacity-40',
      variant === 'primary' && 'bg-brand-default-500 text-text-inverted hover:bg-brand-default-600',
      variant === 'secondary' && 'bg-bg-300 text-text-default hover:bg-bg-500',
      variant === 'destructive' && 'bg-error-500 text-text-inverted hover:bg-error-600',
      variant === 'cta' && 'text-text-inverted font-semibold px-5 py-4 rounded-full bg-brand-default-500 text-base hover:bg-bg-inverted transition-all',
      className,
    )}
    {...props}
  >
    {children}
  </button>
);
