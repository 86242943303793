import React from 'react';

export const Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props,
) => (
  <svg
    width="105"
    height="48"
    viewBox="0 0 105 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3544 13.4787L21.0309 12.2948L16.869 18.7963L13.1396 19.3052L13.9317 25.183L6.16332 26.4992L5.33729 20.3698L2.44211 20.7648L1.65691 14.9384L4.55209 14.5433L3.6205 7.63057C3.33436 5.50737 5.0543 1.95389 8.53834 1.47849C12.0224 1.00309 14.5042 1.61986 14.5042 1.61986L13.7749 7.70327C13.7749 7.70327 13.0546 7.55012 12.5639 7.61708C12.0241 7.69073 11.6091 7.9485 11.7222 8.78791L12.3544 13.4787Z"
      fill="currentColor"
    />
    <path
      d="M5.16604 30.5771L5.03767 36.8722L13.0039 37.0367L13.1548 29.6351C10.3736 29.9363 7.70289 30.2501 5.16604 30.5771Z"
      fill="currentColor"
    />
    <path
      d="M80.9333 29.2975C80.3946 30.1738 78.7483 30.9253 77.2358 30.9253C76.0127 30.9253 76.0135 29.5833 76.0143 28.1308L76.0143 23.6114C71.0774 23.9624 66.0245 24.3408 60.9437 24.7462V37.4363H68.966V34.384C70.1755 36.8375 72.6985 37.8349 75.8162 37.8349C77.9466 37.8349 79.6952 37.1946 80.9333 36.5329V37.4363H88.7575V24.1818C88.7575 23.6681 88.8091 23.1842 88.9079 22.7359C86.3054 22.9046 83.642 23.0819 80.9333 23.2678V29.2975Z"
      fill="currentColor"
    />
    <path
      d="M76.0143 22.1303V19.5478H80.9333V21.9717L89.2457 21.7036C89.9054 20.2361 91.1943 19.3485 92.8676 19.3485C94.4655 19.3485 95.7127 20.137 96.3964 21.473L104.773 21.2029C103.843 15.8923 100.122 12.1233 94.7494 12.1233C92.62 12.1233 90.4411 13.0202 88.7575 14.5649V12.5219H76.0143V4.00121H68.1901V12.5219H61.6399C60.7462 12.2614 59.7851 12.1233 58.7648 12.1233C54.8032 12.1233 51.8816 13.4687 50.0493 15.6113C48.3161 13.4687 45.6916 12.1233 42.5223 12.1233C40.3434 12.1233 38.1645 13.0202 36.4808 14.5649V12.5219H28.6034V13.369C26.9197 12.5717 25.0379 12.1233 23.0571 12.1233C16.2685 12.1233 10.6799 17.5104 10.3014 24.2496L18.4727 23.9861C18.8495 21.2849 20.7095 19.5478 23.3542 19.5478C25.8953 19.5478 27.7119 21.1514 28.1847 23.6728L36.5223 23.404C36.7908 20.9485 38.3443 19.3485 40.591 19.3485C42.7713 19.3485 44.3373 20.8167 44.6802 23.1409L52.8445 22.8776C53.265 20.7223 54.7517 19.3485 56.8336 19.3485C58.8468 19.3485 60.3034 20.6003 60.7776 22.6217L76.0143 22.1303Z"
      fill="currentColor"
    />
    <path
      d="M52.7234 25.4248C50.0562 25.6527 47.3944 25.888 44.7507 26.1307V37.4363H52.7234V25.4248Z"
      fill="currentColor"
    />
    <path
      d="M36.4808 26.9214C33.5124 27.2172 30.5853 27.5226 27.7187 27.8376C26.9221 29.506 25.3649 30.5101 23.3542 30.5101C21.7573 30.5101 20.4464 29.8767 19.5755 28.7802C16.7005 29.1313 13.9129 29.4931 11.235 29.8653C13.1494 34.5517 17.7344 37.8349 23.0571 37.8349C24.9884 37.8349 26.8702 37.3865 28.5043 36.639C28.0091 38.4827 26.6721 40.9243 23.7009 40.9243C19.6402 40.9243 17.1642 37.7851 17.1642 37.7851L11.0733 41.024C11.0733 41.024 14.1435 48 23.7009 48C29.257 48 35.1428 43.9366 36.2839 37.4363H36.4808V26.9214Z"
      fill="currentColor"
    />
    <path
      d="M104.859 21.7573C102.258 21.9081 99.534 22.0702 96.7088 22.2436C96.8845 22.8081 96.9778 23.4416 96.9778 24.132V37.4363H105V23.8829C105 23.1538 104.952 22.4438 104.859 21.7573Z"
      fill="currentColor"
    />
  </svg>
);
