"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    transparent: 'transparent',
    figma: {
        dark: '#222222',
    },
    error: {
        500: '#cb3434',
        600: '#B21E1E',
    },
    warning: {
        500: '#FFD37D',
        700: '#CC8C0F',
    },
    success: {
        500: '#42CB79',
    },
    bg: {
        default: '#ffffff',
        300: '#f8f8f8',
        500: '#e5e5e5',
        inverted: '#292929',
    },
    text: {
        default: '#292929',
        500: '#595959',
        600: '#808080',
        700: '#d1d1d1',
        800: '#ebebeb',
        inverted: '#ffffff',
    },
    brand: {
        default: {
            100: '#e7f7ff',
            300: '#bae3fe',
            400: '#6AC4FF',
            500: '#18a0fb',
            600: '#008CEA',
        },
    },
};
