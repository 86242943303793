import React, { ReactNode } from 'react';
import { Logo } from '@/components/visuals';
import { Button } from '@/components/controls';

type Props = {
  title: string;
  message: ReactNode;
  action?: {
    disabled?: boolean;
    label: string;
    onClick?: () => void;
  };
};

export function StatusPage({ title, message, action }: Props) {
  return (
    <div className="container flex flex-col items-center justify-center">
      <Logo className="text-brand-default-100 col-start-1 row-start-1 w-13 h-auto -mb-6" />
      <div className="col-start-1 row-start-1 mx-auto max-w-sm text-center">
        <p className="text-brand-default-500 text-3xl leading-none uppercase font-semibold">
          {title}
        </p>
        <div className="text-text-default text-base">{message}</div>
        {!!action && (
          <div className="mt-4">
            <Button
              type="button"
              variant="cta"
              disabled={action.disabled}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
