import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from '@/utils/ui';

type Props = {
  title: string;
  className?: string;
};

export function Loader({ title, className }: Props) {
  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <p className="uppercase text-xs font-semibold text-brand-default-500 mb-1">{title}</p>
      <div className="overflow-hidden w-10 h-4 bg-brand-default-100 rounded-full">
        <div className="grid grid-cols-1 grid-rows-1 h-full">
          <motion.div
            id="_547_8_bottom"
            className="col-start-1 row-start-1 z-0 h-full bg-brand-default-300 rounded-full"
            animate={{ x: ['-100%', '0%', '0%', '100%'] }}
            transition={{
              type: 'keyframes',
              ease: [
                [0.162, 0.889, 0.375, 0.917],
                [0, 0, 1, 1],
                [0.732, 0.075, 0.909, 0.403],
              ],
              times: [0, 0.3, 0.7, 1],
              duration: 2.5,
              repeat: Infinity,
              repeatDelay: 0.5,
            }}
          />
          <motion.div
            id="_547_7_middle"
            className="col-start-1 row-start-1 z-10 h-full bg-brand-default-400 rounded-full"
            animate={{ x: ['-100%', '0%', '0%', '100%'] }}
            transition={{
              type: 'keyframes',
              ease: [
                [0, 0, 1, 1],
                [0.162, 0.889, 0.375, 0.917],
                [0, 0, 1, 1],
                [0.775, 0.111, 0.85, 0.352],
              ],
              times: [0, 0.1, 0.4, 0.6, 0.9],
              duration: 2.5,
              repeat: Infinity,
              repeatDelay: 0.5,
            }}
          />
          <motion.div
            id="_547_6_top"
            className="col-start-1 row-start-1 h-full z-20 bg-brand-default-500 rounded-full"
            animate={{ x: ['-100%', '-100%', '0%', '100%'] }}
            transition={{
              type: 'keyframes',
              ease: [
                [0, 0, 1, 1],
                [0.162, 0.889, 0.375, 0.917],
                [0.759, 0.13, 0.862, 0.392],
              ],
              times: [0, 0.2, 0.5, 0.8],
              duration: 2.5,
              repeat: Infinity,
              repeatDelay: 0.5,
            }}
          />
        </div>
      </div>
    </div>
  );
}
