import React, { FC, ReactNode } from 'react';
import cx from 'clsx';

type Props = {
  title?: string
  variant?: 'warning' | 'neutral'
  size?: 'default' | 'small'
  children?: ReactNode
  className?: string
};

export const Note: FC<Props> = ({
  variant = 'warning', size = 'default', title, className, children,
}) => (
  <div
    className={cx(
      size === 'default' && 'py-5 px-6',
      size === 'small' && 'py-3 px-4',
      variant === 'warning' && 'bg-warning-500 border-l-4 border-warning-700',
      variant === 'neutral' && 'bg-bg-300 border-l-4 border-bg-500',
      className,
    )}
  >
    {!!title && (
      <p className="text-lg font-semibold text-warning-700 mb-2">{title}</p>
    )}
    <p
      className={cx(
        'text-text-default',
        size === 'default' && 'text-sm',
        size === 'small' && 'text-xs',
      )}
    >
      {children}
    </p>
  </div>
);
