/* eslint-disable */
import React, { ErrorInfo, PropsWithChildren } from 'react';
import ErrorView from 'next/error';
import Bugsnag, { Plugin, Client } from '@bugsnag/js';
import pkg from '@/../package.json';
import { TermlyRef } from '@/utils/termly';

type State = {
  error: Error | null
  info: ErrorInfo | null
};

type Props = PropsWithChildren<{}>;

const formatComponentStack = (str: string) => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = '';
  for (let line = 0, len = lines.length; line < len; line += 1) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`;
  }
  return ret;
};

export class ErrorBoundary extends React.Component<Props, State> {
  public static bugsnagClient: Client | null = null;

  public static bugsnagPlugin: Plugin = {
    name: 'bugsnag/react',
    load: (client) => {
      ErrorBoundary.bugsnagClient = client;
    },
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      info: null,
    };
  }

  public componentDidMount() {
    TermlyRef.subscribe((state) => {
      if (!process.env.BUGSNAG_KEY) return;
      if (state.performance) {
        if (!Bugsnag.isStarted()) {
          Bugsnag.start({
            collectUserIp: false,
            apiKey: process.env.BUGSNAG_KEY,
            appVersion: pkg.version,
            releaseStage: process.env.SANITY_DATASET,
            plugins: [ErrorBoundary.bugsnagPlugin],
          });
        } else {
          Bugsnag.resumeSession();
        }
      } else if (Bugsnag.isStarted()) {
        Bugsnag.pauseSession();
      }
    });
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    if (ErrorBoundary.bugsnagClient) {
      const client = ErrorBoundary.bugsnagClient;
      const handledState = { severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' } };
      const event = client.Event.create(error, true, handledState, '', 1);
      if (info && info.componentStack) {
        info.componentStack = formatComponentStack(info.componentStack);
      }
      event.addMetadata('react', info);
      client._notify(event);
    }
    this.setState({ error, info });
  }

  // eslint-disable-next-line
  public handleClearError = () => {
    this.setState({
      error: null,
    });
  };

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorView statusCode={500} />;
    }

    return children;
  }
}
