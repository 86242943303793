import React from 'react';

export function PremiumIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.7369 8.18175C34.2215 5.27275 38.3781 5.27275 39.8626 8.18175L46.6162 21.4154C47.1974 22.5542 48.288 23.3466 49.5508 23.5475L64.2236 25.8811C67.449 26.394 68.7335 30.3472 66.4256 32.658L55.9267 43.1705C55.0231 44.0752 54.6065 45.3573 54.8057 46.6203L57.1205 61.2961C57.6294 64.5222 54.2666 66.9654 51.3557 65.4845L38.1134 58.748C36.9738 58.1682 35.6257 58.1682 34.4861 58.748L21.2438 65.4845C18.3329 66.9654 14.9701 64.5222 15.479 61.2962L17.7938 46.6203C17.993 45.3573 17.5764 44.0752 16.6728 43.1705L6.17389 32.658C3.86602 30.3472 5.15048 26.394 8.37586 25.8811L23.0487 23.5475C24.3115 23.3466 25.4021 22.5542 25.9833 21.4154L32.7369 8.18175Z"
      />
    </svg>
  );
}
