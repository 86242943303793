import {
  autoUpdate, Middleware, Placement, useFloating,
} from '@floating-ui/react-dom';
import React, { useCallback, useRef, useState } from 'react';
import { Portal } from 'react-portal';

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> & {
  delay?: number;
  placement?: Placement
  middleware?: Middleware[]
  content?: React.ReactNode;
};

export const Tooltip: React.FC<Props> = ({
  children, delay = 0, content, middleware, placement = 'top', ...props
}) => {
  const [hover, setHover] = useState(false);
  const {
    x, y, refs, strategy,
  } = useFloating({
    placement,
    middleware,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const showTooltipTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handlePointerOver = useCallback(() => {
    if (showTooltipTimeout.current) {
      clearTimeout(showTooltipTimeout.current);
    }
    showTooltipTimeout.current = setTimeout(() => {
      setHover(true);
    }, delay);
  }, [delay]);

  const handlePointerOut = useCallback(() => {
    if (showTooltipTimeout.current) {
      clearTimeout(showTooltipTimeout.current);
    }
    setHover(false);
  }, []);

  return (
    <>
      <div
        ref={content ? refs.setReference : undefined}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        {...props}
      >
        {children}
      </div>
      {(hover && content) && (
        <Portal>
          <div
            ref={refs.setFloating}
            className="z-50 max-w-xs text-xs"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className="bg-bg-inverted/90 text-text-inverted p-3 rounded-md">
              {content}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
