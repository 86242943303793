import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement> & {
  open?: boolean;
};

export const LayersIcon: React.FC<Props> = ({ open = true, ...props }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" fillOpacity={open ? 1 : 0.5} d="M6.74417 10.7104L2.445 7.36789L1.5 8.10289L6.75 12.1862L12 8.10289L11.0492 7.36206L6.74417 10.7104Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.75 9.22874L11.0433 5.88624L12 5.14541L6.75 1.06207L1.5 5.14541L2.45083 5.88624L6.75 9.22874ZM10.0983 5.14541L6.75 2.53791L3.40166 5.14541L6.75 7.75291L10.0983 5.14541Z" />
  </svg>
);
