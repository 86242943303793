import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement>;

export function HorizontalPaddingIcon(props: Props) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="20" width="8" height="60" rx="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.6667 28H36.3333C31.731 28 28 31.731 28 36.3333V63.6667C28 68.269 31.731 72 36.3333 72H63.6667C68.269 72 72 68.269 72 63.6667V36.3333C72 31.731 68.269 28 63.6667 28ZM36.3333 20C27.3127 20 20 27.3127 20 36.3333V63.6667C20 72.6873 27.3127 80 36.3333 80H63.6667C72.6873 80 80 72.6873 80 63.6667V36.3333C80 27.3127 72.6873 20 63.6667 20H36.3333Z"
      />
      <rect x="90" y="20" width="8" height="60" rx="4" />
    </svg>
  );
}
