import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement> & {
  type: NodeType;
};

export const LayerTypeIcon: React.FC<Props> = ({ type, ...props }) => {
  const icon = React.useMemo(() => {
    if (type === 'TEXT' || type === 'SHAPE_WITH_TEXT') {
      return (
        <g id="text">
          <path d="M1.52515 2.5H8.52515V3.5H5.52515V10.5H4.52515V3.5H1.52515V2.5Z" />
          <path d="M10.7678 5.5L11.475 6.20715L9.70728 7.97485L11.475 9.74255L10.7678 10.4497L9.00012 8.68201L7.2323 10.4497L6.52515 9.74268L8.29297 7.97485L6.52515 6.20703L7.2323 5.5L9.00012 7.2677L10.7678 5.5Z" />
        </g>
      );
    }

    if (type === 'COMPONENT' || type === 'COMPONENT_SET') {
      return (
        <g id="component">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.67157 2.96441L6.5 0.135986L9.32843 2.96441L6.5 5.79284L3.67157 2.96441ZM6.5 1.5502L7.91421 2.96441L6.5 4.37863L5.08579 2.96441L6.5 1.5502Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0.136039 6.49995L2.96447 3.67152L5.79289 6.49995L2.96447 9.32837L0.136039 6.49995ZM2.96447 5.08573L4.37868 6.49995L2.96447 7.91416L1.55025 6.49995L2.96447 5.08573Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10.0355 3.67152L7.20711 6.49995L10.0355 9.32837L12.864 6.49995L10.0355 3.67152ZM11.4497 6.49995L10.0355 5.08573L8.62132 6.49995L10.0355 7.91416L11.4497 6.49995Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M3.67157 10.0355L6.5 7.20705L9.32843 10.0355L6.5 12.8639L3.67157 10.0355ZM6.5 8.62127L7.91421 10.0355L6.5 11.4497L5.08579 10.0355L6.5 8.62127Z" />
        </g>
      );
    }

    if (type === 'FRAME' || type === 'GROUP') {
      return (
        <path id="frame" fillRule="evenodd" clipRule="evenodd" d="M1.5 4.5H2.5V8.5H1.5V11.5H4.5V10.5H8.5V11.5H11.5V8.5H10.5V4.5H11.5V1.5H8.5V2.5H4.5V1.5H1.5V4.5ZM3.5 2.5H2.5V3.5H3.5V2.5ZM4.5 9.5H8.5V8.5H9.5V4.5H8.5V3.5H4.5V4.5H3.5V8.5H4.5V9.5ZM2.5 9.5V10.5H3.5V9.5H2.5ZM9.5 10.5V9.5H10.5V10.5H9.5ZM9.5 3.5H10.5V2.5H9.5V3.5Z" />
      );
    }

    if (type === 'ELLIPSE') {
      return (
        <path id="circle" fillRule="evenodd" clipRule="evenodd" d="M6.5 3.5C4.84315 3.5 3.5 4.84315 3.5 6.5C3.5 8.15685 4.84315 9.5 6.5 9.5C8.15685 9.5 9.5 8.15685 9.5 6.5C9.5 4.84315 8.15685 3.5 6.5 3.5ZM2.5 6.5C2.5 4.29086 4.29086 2.5 6.5 2.5C8.70914 2.5 10.5 4.29086 10.5 6.5C10.5 8.70914 8.70914 10.5 6.5 10.5C4.29086 10.5 2.5 8.70914 2.5 6.5Z" />
      );
    }

    if (type === 'RECTANGLE') {
      return (
        <path id="rectangle" fillRule="evenodd" clipRule="evenodd" d="M9.5 3.5H3.5V9.5H9.5V3.5ZM2.5 2.5V10.5H10.5V2.5H2.5Z" />
      );
    }

    if (type === 'INSTANCE') {
      return (
        <path id="instance" fillRule="evenodd" clipRule="evenodd" d="M6.5 1.55017L11.4497 6.49992L6.5 11.4497L1.55025 6.49992L6.5 1.55017ZM6.5 2.96438L2.96447 6.49992L6.5 10.0355L10.0355 6.49992L6.5 2.96438Z" />
      );
    }

    return null;
  }, [type]);

  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* <g id="image">
        <path d="M6.14286 7.845L5.25 6.61364L4 9H9L7.39286 6L6.14286 7.845Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2H11V11H2V2ZM3 3H10V10H3V3Z" />
      </g> */}
      {icon || (
        <path
          id="path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.56028 3.01071C7.77856 2.14267 8.56423 1.5 9.5 1.5C10.6046 1.5 11.5 2.39543 11.5 3.5C11.5 4.60457 10.6046 5.5 9.5 5.5C8.5723 5.5 7.79212 4.86837 7.56606 4.0117C6.37637 4.07785 5.54121 4.42251 4.99019 4.99261C4.45464 5.54672 4.12111 6.38227 4.05602 7.57831C4.89007 7.8192 5.5 8.58837 5.5 9.5C5.5 10.6046 4.60457 11.5 3.5 11.5C2.39543 11.5 1.5 10.6046 1.5 9.5C1.5 8.54793 2.16525 7.75124 3.05627 7.5494C3.12681 6.18513 3.51177 5.08333 4.27115 4.29765C5.06144 3.47997 6.18491 3.07926 7.56028 3.01071ZM9.5 2.5C8.94772 2.5 8.5 2.94772 8.5 3.5C8.5 4.05228 8.94772 4.5 9.5 4.5C10.0523 4.5 10.5 4.05228 10.5 3.5C10.5 2.94772 10.0523 2.5 9.5 2.5ZM3.5 8.5C2.94772 8.5 2.5 8.94772 2.5 9.5C2.5 10.0523 2.94772 10.5 3.5 10.5C4.05228 10.5 4.5 10.0523 4.5 9.5C4.5 8.94772 4.05228 8.5 3.5 8.5Z"
        />
      )}
    </svg>
  );
};
