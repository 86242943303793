import React, {
  ForwardedRef,
  forwardRef,
  ReactNode, useCallback, useImperativeHandle, useMemo, useState,
} from 'react';
import { motion } from 'framer-motion';

type AccordionRef = {
  isOpen: boolean
  toggle: (open?: boolean) => void
};

type Props = {
  mode?: 'controlled' | 'uncontrolled'
  id: string
  header: ReactNode
  children: ReactNode
  className?: string
} & ({
  mode?: 'uncontrolled'
  onToggle?: (open: boolean) => void
} | {
  mode: 'controlled'
  isOpen: boolean
  onToggle: (open: boolean) => void
});

const RawAccordion = ({
  id, header, children, className, ...props
}: Props, ref: ForwardedRef<AccordionRef>) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const actualIsOpen = props.mode === 'controlled' ? props.isOpen : internalIsOpen;

  const toggle = useCallback((open?: boolean) => {
    if (props.mode === 'controlled') {
      const next = typeof open === 'boolean' ? open : !props.isOpen;
      props.onToggle(next);
    } else if (props.mode === 'uncontrolled') {
      const next = typeof open === 'boolean' ? open : !internalIsOpen;
      props.onToggle?.(next);
      setInternalIsOpen(next);
    }
  }, [internalIsOpen, props]);

  const handleClick = useCallback(() => {
    toggle();
  }, [toggle]);

  useImperativeHandle(ref, () => ({
    isOpen: props.mode === 'controlled' ? props.isOpen : internalIsOpen,
    toggle,
  }));

  return (
    <div id={id} className={className}>
      <button
        type="button"
        id={`${id}|trigger`}
        aria-expanded={actualIsOpen ? 'true' : 'false'}
        aria-controls={`${id}|content`}
        className="text-base font-medium"
        onClick={handleClick}
      >
        {header}
      </button>
      <motion.div
        id={`${id}|content`}
        initial="closed"
        exit="closed"
        role="region"
        className="overflow-hidden"
        aria-labelledby={`${id}|trigger`}
        animate={actualIsOpen ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto' },
          closed: { height: '0' },
        }}
      >
        <div className="py-3">
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export const Accordion = forwardRef(RawAccordion);
