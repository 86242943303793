import React from 'react';

type Props = React.SVGAttributes<SVGSVGElement>;

export const SetAnchorPointIcon: React.FC<Props> = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2.75C8.91421 2.75 9.25 3.08579 9.25 3.5V5.09451C10.3042 5.36585 11.1342 6.19579 11.4055 7.25H13C13.4142 7.25 13.75 7.58579 13.75 8C13.75 8.41421 13.4142 8.75 13 8.75H11.4055C11.1342 9.80421 10.3042 10.6342 9.25 10.9055L9.25 12.5C9.25 12.9142 8.91421 13.25 8.5 13.25C8.08579 13.25 7.75 12.9142 7.75 12.5L7.75 10.9055C6.69579 10.6342 5.86585 9.80421 5.59451 8.75H4C3.58579 8.75 3.25 8.41421 3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H5.59451C5.86585 6.19579 6.69579 5.36585 7.75 5.09451V3.5C7.75 3.08579 8.08579 2.75 8.5 2.75ZM10 8C10 7.17157 9.32843 6.5 8.5 6.5C7.67157 6.5 7 7.17157 7 8C7 8.82843 7.67157 9.5 8.5 9.5C9.32843 9.5 10 8.82843 10 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.5H4.5C3.11929 1.5 2 2.61929 2 4V12C2 13.3807 3.11929 14.5 4.5 14.5H12.5C13.8807 14.5 15 13.3807 15 12V4C15 2.61929 13.8807 1.5 12.5 1.5ZM4.5 0C2.29086 0 0.5 1.79086 0.5 4V12C0.5 14.2091 2.29086 16 4.5 16H12.5C14.7091 16 16.5 14.2091 16.5 12V4C16.5 1.79086 14.7091 0 12.5 0H4.5Z"
    />
  </svg>
);
