import React from 'react';

type Props = {
  title: string;
  features: string[];
  pricing?: {
    monthly: number;
    yearly: number;
  };
  action?: {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
  };
};

export function PlanBox({ title, features, pricing, action }: Props) {
  // const monthly12 = pricing ? pricing.monthly * 12 : null;
  // const saving =
  //   pricing && monthly12 ? (monthly12 - pricing.yearly) / monthly12 : null;

  return (
    <article className="flex w-full shadow-lg shadow-bg-inverted/10 rounded-lg transition-all border border-bg-500">
      <div className="w-full px-5 pt-6 pb-4 grid grid-cols-1 grid-rows-[auto_1fr_auto_auto] gap-5">
        <div className="flex">
          <h3 className="font-semibold text-base py-2 px-5 bg-brand-default-500 text-text-inverted rounded-full">
            {title}
          </h3>
        </div>

        <div>
          <ul className="list-disc pl-6">
            {features.map((feature) => (
              <li key={feature}>{feature}</li>
            ))}
          </ul>
        </div>

        <div>
          {!!pricing && (
            <div className="mb-3">
              <p className="leading-none">
                <span className="text-3xl font-semibold">
                  ${pricing.monthly}
                </span>{' '}
                per month
              </p>
              <p className="text-xs text-text-500">* excluding taxes, 7-day free trial for new customers.</p>
            </div>
          )}

          {!!action && (
            <div>
              <button
                type="button"
                disabled={action.disabled}
                className="
                block text-center py-3 px-5 border-2 border-brand-default-500 text-brand-default-500
                font-medium text-base rounded-full transition-all hover:bg-brand-default-100
                disabled:pointer-events-none disabled:opacity-50
                "
                onClick={action.onClick}
              >
                {action.label}
              </button>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}
