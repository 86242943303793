import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const ComponentIcon: React.FC<Props> = (props) => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.959 24.1421C156.149 16.3317 143.486 16.3316 135.675 24.1421L103.169 56.648C95.3588 64.4585 95.3588 77.1218 103.169 84.9322L135.675 117.438C143.486 125.249 156.149 125.249 163.959 117.438L196.465 84.9323C204.276 77.1218 204.276 64.4585 196.465 56.648L163.959 24.1421ZM84.9323 103.169C77.1218 95.3588 64.4585 95.3588 56.648 103.169L24.1422 135.675C16.3317 143.486 16.3317 156.149 24.1422 163.959L56.648 196.465C64.4585 204.276 77.1218 204.276 84.9323 196.465L117.438 163.959C125.249 156.149 125.249 143.486 117.438 135.675L84.9323 103.169ZM214.702 103.169C222.513 95.3587 235.176 95.3588 242.986 103.169L275.492 135.675C283.303 143.486 283.303 156.149 275.492 163.959L242.987 196.465C235.176 204.276 222.513 204.276 214.702 196.465L182.196 163.959C174.386 156.149 174.386 143.486 182.196 135.675L214.702 103.169ZM163.959 182.196C156.149 174.386 143.486 174.386 135.675 182.196L103.169 214.702C95.3588 222.513 95.3588 235.176 103.169 242.987L135.675 275.492C143.486 283.303 156.149 283.303 163.959 275.492L196.465 242.987C204.276 235.176 204.276 222.513 196.465 214.702L163.959 182.196Z"
      fill="black"
    />
  </svg>
);
